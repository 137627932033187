// Generated by Framer (1c1e241)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ya4Bu1xzQ", "f1vNEC70b", "aEGRK1QWX"];

const serializationHash = "framer-zIF4P"

const variantClassNames = {aEGRK1QWX: "framer-v-5nvcer", f1vNEC70b: "framer-v-1n384qh", ya4Bu1xzQ: "framer-v-kdgdho"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Closed: "ya4Bu1xzQ", Hover: "aEGRK1QWX", Open: "f1vNEC70b"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ya4Bu1xzQ"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ya4Bu1xzQ", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-zIF4P", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-kdgdho", className)} data-framer-name={"Closed"} layoutDependency={layoutDependency} layoutId={"ya4Bu1xzQ"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({aEGRK1QWX: {"data-framer-name": "Hover"}, f1vNEC70b: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-fb9lr8"} layoutDependency={layoutDependency} layoutId={"pS7JL7uKV"} style={{rotate: 0}} variants={{f1vNEC70b: {rotate: -180}}}><SVG className={"framer-zddwnd"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"pUHq7cXOt"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 3 5.5 L 7 9.5 L 11 5.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"var(--token-1fe56cfa-d2fd-419f-acc6-c63b3e1e5afa, rgb(140, 137, 134))\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={547991068} withExternalLayout {...addPropertyOverrides({aEGRK1QWX: {svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 3 5.5 L 7 9.5 L 11 5.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"var(--token-63f7635c-4044-4753-b791-268bd3d1a479, rgb(69, 94, 237))\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>", svgContentId: 3800276163}, f1vNEC70b: {svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 3 5.5 L 7 9.5 L 11 5.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"var(--token-63f7635c-4044-4753-b791-268bd3d1a479, rgb(69, 94, 237))\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>", svgContentId: 3800276163}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-zIF4P [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zIF4P .framer-iei48b { display: block; }", ".framer-zIF4P .framer-kdgdho { height: 14px; overflow: visible; position: relative; width: 14px; }", ".framer-zIF4P .framer-fb9lr8 { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }", ".framer-zIF4P .framer-zddwnd { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"f1vNEC70b":{"layout":["fixed","fixed"]},"aEGRK1QWX":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const Framerz6ISJ2XUa: React.ComponentType<Props> = withCSS(Component, css, "framer-zIF4P") as typeof Component;
export default Framerz6ISJ2XUa;

Framerz6ISJ2XUa.displayName = "Caret";

Framerz6ISJ2XUa.defaultProps = {height: 14, width: 14};

addPropertyControls(Framerz6ISJ2XUa, {variant: {options: ["ya4Bu1xzQ", "f1vNEC70b", "aEGRK1QWX"], optionTitles: ["Closed", "Open", "Hover"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerz6ISJ2XUa, [])